import * as React from "react";
import { GradientHeading } from "components/GradientHeading";
import type { Props } from "components/StrokeHeading";

export const AnnouncementHeading: React.FC<Pick<Props, "as">> = ({ as }) => {
    // noinspection AllyJsxHardcodedStringInspection
    return (
        <GradientHeading as={as}>
            Announ<span className="md:hidden">-</span>
            <br className="md:hidden" />
            cements
        </GradientHeading>
    );
};
