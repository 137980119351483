import React, { useEffect } from "react";
import { graphql, PageProps } from "gatsby";
import { Link, useI18next } from "gatsby-plugin-react-i18next";
import { Layout } from "components/Layout";
import { AnnouncementHeading } from "components/AnnouncementHeading";
import { Article } from "components/Article";
import { ReactComponent as AngleLeft } from "icons/angle-left.svg";

function BackToList() {
    // noinspection AllyJsxHardcodedStringInspection
    return (
        <Link
            to="/News"
            className="inline-flex items-center mx-auto text-xl lg:text-3.5xl"
        >
            <AngleLeft className="text-lg lg:text-2xl align-middle mr-2 lg:mr-4" />
            Back To List
        </Link>
    );
}

export default function News({ data }: PageProps<Queries.NewsQuery>) {
    const { navigate } = useI18next();

    useEffect(() => {
        if (data.locales && !data.news) navigate("/404");
    }, [data]);

    if (!data.news) return null;

    return (
        <Layout>
            <div className="mx-auto max-w-wide page-trailer">
                <AnnouncementHeading />
                <div className="h-6 lg:h-4 bg-gradient-100 lg:bg-gradient-200" />
                <div className="mt-4 lg:mt-12 px-4">
                    <article className="mx-auto max-w-container">
                        <div className="flex flex-col lg:flex-row lg:items-center font-bold border-b-5 pb-4">
                            <time className="lg:text-2xl italic mb-4 lg:mb-0 mr-4">
                                {data?.news?.frontmatter?.published}
                            </time>
                            <h1 className="text-xl lg:text-4xl">
                                {data?.news?.frontmatter?.title}
                            </h1>
                        </div>
                        <Article
                            className="py-12 lg:py-16 mb-12 border-b-5"
                            dangerouslySetInnerHTML={{
                                __html: data?.news?.html ?? "",
                            }}
                        />
                        <div className="flex">
                            <BackToList />
                        </div>
                    </article>
                </div>
            </div>
        </Layout>
    );
}

export const query = graphql`
    query News(
        $language: String!
        $frontmatter: MarkdownRemarkFrontmatterFilterInput!
    ) {
        locales: allLocale(filter: { language: { eq: $language } }) {
            edges {
                node {
                    ns
                    data
                    language
                }
            }
        }
        news: markdownRemark(
            frontmatter: $frontmatter
            fields: { language: { eq: $language }, pending: { ne: true } }
        ) {
            id
            html
            frontmatter {
                title
                published(formatString: "YYYY.MM.DD")
            }
        }
    }
`;

export { Head } from "components/GCSRewriteURL";
