import * as React from "react";
import cx from "classnames";
import { StrokeHeading, Props } from "components/StrokeHeading";

export const GradientHeading: React.FC<Props> = ({ className, ...props }) => {
    return (
        <div
            className={cx(
                "grid grid-rows-4 lg:grid-rows-5 lg:pt-28",
                className
            )}
        >
            <div className="col-span-full row-start-4 row-span-1 lg:row-start-4 lg:row-span-2 bg-gradient-100 lg:bg-gradient-200" />
            <div className="flex justify-center col-span-full row-span-full">
                <StrokeHeading {...props} />
            </div>
        </div>
    );
};
