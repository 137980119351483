import * as React from "react";
import cx from "classnames";

export type Props = {
    as?: keyof JSX.IntrinsicElements;
} & React.HTMLAttributes<HTMLHeadingElement>;

export const StrokeHeading: React.FC<Props> = ({
    className,
    as = "h1",
    children,
    ...props
}) => {
    return React.createElement(
        as,
        {
            className: cx(
                "font-bold text-5xl md:text-[length:min(155px,7.69vw)] uppercase italic title-stroke",
                className
            ),
            ...props,
        },
        children
    );
};
