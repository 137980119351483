import React from "react";
import cx from "classnames";

export const Article: React.FC<React.HTMLAttributes<HTMLDivElement>> = ({
    className,
    ...props
}) => {
    return (
        <article
            className={cx(
                "prose prose-sm lg:prose-2xl max-w-none",
                "prose-headings:text-base prose-headings:font-bold lg:prose-headings:text-3.5xl",
                "prose-a:text-purple-200 prose-hr:border-black",
                className
            )}
            {...props}
        />
    );
};
